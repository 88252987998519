import React from "react";
import { connect } from "react-redux";

import MapWrapper from "../mapWrapper";
import MapPopUp from "../mapPopUp";
import LayoutController from "../LayoutController";

import "./style.scss";

const FrontPage = ({ data, popUpOpen, currentPath }) => {

  return (
    <div>
      <LayoutController currentPath={currentPath} title="Kierratys.info">
        <h1 className="hidden">Kierrätys.info</h1>
        <div id="pageContent">
          {popUpOpen ?<MapPopUp />:null}
        
          <MapWrapper data={data} />
        </div>
      </LayoutController>
    </div>
  );
};

export default connect(state => ({
  popUpOpen: state.app.popUpOpen,

}), null)(FrontPage);
